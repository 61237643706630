/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    string, object, func, bool, number,
} from 'prop-types';
import TextField from '@material-ui/core/TextField';
import CUSTOM_BREAKPOINTS from '../../../../../../helpers/customBreakpoints';

const styles = (theme) => ({
    zipInputRoot: {
        borderBottom: theme.palette.gfMobileInputBorderBottom,
        width: '100%',
        marginBottom: 14,
        '&::before': {
            position: 'absolute',
            zIndex: '998',
            color: theme.palette.white,
            lineHeight: '25px',
            fontSize: theme.palette?.text?.fontSize11 || '11px',
            fontWeight: '300',
            content: '"Invalid zip code"',
            bottom: '-25px',
            left: 0,
            paddingLeft: '7px',
            width: '100%',
            height: '25px',
            backgroundColor: theme.palette.colorAlert,
            transition: 'opacity 0.3s ease-in',
            opacity: 0,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            borderBottom: 'none',
            marginBottom: 0,
        },
        '& input': {
            fontSize: theme.typography?.fontSize || '16px',
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                height: '32px',
                backgroundColor: theme.palette.white,
                padding: '0 22px 0 10px',
                fontSize: theme.palette?.text?.fontSize12 || '12px',
                border: theme.palette.gfTabDesktopInputBorder,
                borderRadius: '3px',
                '&::placeholder': {
                    color: '#666666',
                    opacity: '1',
                },
            },
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
        },
    },
    paddingRight: {
        '& input': {
            '&:focus': {
                outline: '1px solid',
            },
        },
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            paddingRight: 13,
        },
    },
    addOpacity: {
        '&::before': {
            opacity: 1,
        },
    },
    zipLabelRoot: {
        fontSize: theme.typography?.fontSize || '16px',
        color: theme.palette.gfMobileLabelTextColor || '#212121',
        letterSpacing: '0.42px',
        zIndex: 9,
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            color: theme.palette.white,
            fontSize: theme.palette?.text?.fontSize12 || '12px',
            fontWeight: 300,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        '&:hover': {
            borderBottom: 'none',
        },
        '&$zipLabelFocused': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                color: theme.palette.white,
            },
        },
    },
    zipLabelWarning: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.colorAlert,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"!"',
            fontSize: theme.palette?.text?.fontSize11 || '11px',
            fontWeight: 'bold',
            lineHeight: '16px',
            right: '-20px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: '$fadeZoomIn 0.3s ease-in',
        },
        '&$zipLabelFocused': {
            color: theme.palette.colorAlert,
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                color: theme.palette.white,
            },
        },
    },
    ctZipErrorText: {
        '&::after': {
            position: 'relative',
            marginLeft: '7px',
            zIndex: '999',
            color: theme.palette.colorAlert,
            textAlign: 'center',
            content: '"Invalid ZIP code"',
            fontSize: theme.typography?.fontSize || '16px',
            lineHeight: '16px',
            animation: '$fadeZoomIn 0.3s ease-in',
        },
        '& + div > input, & + div > input:focus, & + div > input:active': {
            boxShadow: `0px 0px 0px 1px ${theme.palette.colorAlert}`,
        },
    },
    zipLabelSuccess: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.colorSuccess,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            fontSize: theme.palette?.text?.fontSize10 || '10px',
            fontWeight: 'bold',
            lineHeight: '15px',
            right: '-20px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: '$fadeZoomIn 0.3s ease-in',
        },
    },
    zipLabelFocused: {},
    shrink: {
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    },
    inputMarginTop: {
        'label + &': {
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '22px',
            },
        },
    },
    inputMarginTopGift: {
        'label + &': {
            marginTop: '13px',
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '13px',
            },
        },
    },
    ctInputMarginTopGift: {
        'label + &': {
            marginTop: '20px',
        },
    },
    '@keyframes fadeZoomIn': {
        '0%': { opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)' },
        '100%': { opacity: 1 },
    },
    zipLabelGiftFinderFocused: {
        fontSize: theme.palette?.text?.fontSize13 || '13px',
    },
});

const ZipInputField = memo((props) => {
    const {
        zipLabel, classes, handleChange, onSubmitCheck, giftFinderCodeTheoryView,
        zipcodeIsValid, userSubmittedZip, currentInputLength, value, giftFinderBodyTextColor, inputBorderColor, giftFinderFieldStyle, isMobile, isLoadingOrVariableSkip,
    } = props;

    // if zipcode is not valid show invalid zip and circle with '!'
    let zipInputRootAddOpac;
    let zipLabelRootWarning;
    let ctZipLabelRootWarning;
    if (zipcodeIsValid === false && currentInputLength >= 5) {
        zipInputRootAddOpac = `${classes.addOpacity}`; // add opacity 1 to ::before
        zipLabelRootWarning = `${classes.zipLabelWarning}`; // add class to show circle
        ctZipLabelRootWarning = `${classes.ctZipErrorText} ${giftFinderFieldStyle.errorMessage}`;
    }

    // if user clicks submit and is empty or less then 5 show error
    let zipInputRootEmptyZip;
    let ctZipInputRootEmptyZip;
    if (onSubmitCheck === true) {
        zipInputRootEmptyZip = `${classes.addOpacity}`;
        ctZipInputRootEmptyZip = `${classes.ctZipErrorText} ${giftFinderFieldStyle.errorMessage}`;
    }

    // if zipcodeIsValid show success check
    let zipLabelRootSuccess;
    if (zipcodeIsValid === true && currentInputLength >= 5) { zipLabelRootSuccess = `${classes.zipLabelSuccess} ${giftFinderFieldStyle.successMessage}`; ctZipInputRootEmptyZip = ''; }

    if (giftFinderCodeTheoryView) {
        return (
            // Material UI component
            <TextField
                fullWidth
                label={zipLabel}
                placeholder={isLoadingOrVariableSkip ? '' : 'ZIP code'}
                id="zipcode-native-label-placeholder"
                data-test="hp-zipcode-native-label-placeholder"
                name="zipcode"
                defaultValue={userSubmittedZip}
                InputProps={{
                    style: { border: `1px solid ${inputBorderColor}` },
                    inputProps: { maxLength: 7 },
                    disableUnderline: true,
                    classes: {
                        root: `${giftFinderFieldStyle.ctSelect} ${zipInputRootEmptyZip} ${zipInputRootAddOpac} ${classes.ctInputMarginTopGift}`,
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    style: {
                        color: giftFinderBodyTextColor,
                    },
                    classes: {
                        root: `${isMobile ? giftFinderFieldStyle.inputLabelMobile : giftFinderFieldStyle.ctInputLabel} ${giftFinderFieldStyle.ctInputLabelZipMobile} ${classes.giftFinderField} ${classes.zipLabelRoot} ${ctZipLabelRootWarning} ${zipLabelRootSuccess} ${ctZipInputRootEmptyZip}`,
                        focused: classes.zipLabelGiftFinderFocused,
                    },
                }}
                onChange={handleChange('zip')}
                value={value}
            />
        );
    }

    return (
    // Material UI component
        <TextField
            fullWidth
            label={zipLabel}
            placeholder={isLoadingOrVariableSkip ? '' : 'Delivery zip code'}
            id="zipcode-native-label-placeholder"
            data-test="hp-zipcode-native-label-placeholder"
            name="zipcode"
            defaultValue={userSubmittedZip}
            className={classes.paddingRight}
            InputProps={{
                inputProps: { maxLength: 7 },
                disableUnderline: true,
                classes: {
                    root: `${classes.zipInputRoot}  ${zipInputRootAddOpac} ${classes.inputMarginTop}`,
                },
            }}
            InputLabelProps={{
                style: {
                    color: giftFinderBodyTextColor,
                },
                shrink: true,
                classes: {
                    shrink: classes.shrink,
                    root: `${classes.zipLabelRoot} ${zipLabelRootWarning} ${zipLabelRootSuccess}`,
                    focused: classes.zipLabelFocused,
                },
            }}
            onChange={handleChange('zip')}
            value={value}
        />
    );
});

ZipInputField.propTypes = {
    zipLabel: string.isRequired,
    classes: object.isRequired,
    handleChange: func.isRequired,
    onSubmitCheck: bool.isRequired,
    zipcodeIsValid: bool,
    userSubmittedZip: string.isRequired,
    currentInputLength: number.isRequired,
    value: string.isRequired,
    inputBorderColor: string,
    giftFinderBodyTextColor: string,
    giftFinderCodeTheoryView: bool,
    giftFinderFieldStyle: object, // classes
    isMobile: bool,
    isLoadingOrVariableSkip: bool.isRequired,
};

ZipInputField.defaultProps = {
    zipcodeIsValid: null,
    inputBorderColor: '',
    giftFinderBodyTextColor: '',
    giftFinderCodeTheoryView: false,
    giftFinderFieldStyle: {},
    isMobile: false,
};

export default withStyles(styles)(ZipInputField);
